import { useState } from "react";
import styled from "styled-components";
import InputComponent from "../../Components/InputComponent/InputComponent";
import SelectComponent from "../../Components/SelectComponent/SelectComponent";
import { db } from "../../Firebase/firebase";
import { serverTimestamp } from "firebase/firestore";

import "./Demo.css";
import { toast } from "react-toastify";
export default function Demo() {
    const [data, setData] = useState({
        name: "",
        email: "",
        contact: "",
        age: "",
        course: "",
        courseNumber: "",
    });
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(name);
        if (name === "name")
            setData((prev) => ({
                ...prev,
                name: value,
            }));
        else if (name === "email")
            setData((prev) => ({
                ...prev,
                email: value,
            }));
        else if (name === "contact")
            setData((prev) => ({
                ...prev,
                contact: value,
            }));
        else if (name === "age")
            setData((prev) => ({
                ...prev,
                age: value,
            }));
        else if (name === "course")
            setData((prev) => ({
                ...prev,
                course: value,
            }));
        else if (name === "courseNumber")
            setData((prev) => ({
                ...prev,
                courseNumber: value,
            }));
    };
    const handleSubmit = () => {

        setData({
            name: "",
            email: "",
            contact: "",
            age: "",
            course: "",
            courseNumber: "",
        });
        db.collection("demoRequests")
            .add({
                ...data,
                timestamp: serverTimestamp(),
            })
            .then((res) => {
                setData({
                    name: "",
                    email: "",
                    contact: "",
                    age: "",
                    course: "",
                    courseNumber: "",
                });
                toast.success("Your Demo Request Sent,  Our Team will get back to you soon.")
            })
            .catch((err) => alert(err));
    };
    return (
        <Container>
            <CardContainer>
                <h1>
                    Register yourself to enroll in our free demo classes.
                </h1>
                <p>
                    Once you register yourself, our team will contact you within 24 hours.
                </p>
            </CardContainer>
            <FormContainer>
                <InputComponent
                    value={data.name}
                    handleChange={handleChange}
                    name='name'
                    title='Name'
                    type='text'
                />
                <InputComponent
                    value={data.email}
                    handleChange={handleChange}
                    name='email'
                    title='Email'
                    type='email'
                />
                <InputComponent
                    value={data.contact}
                    handleChange={handleChange}
                    name='contact'
                    title='Contact'
                    type='text'
                />
                <InputComponent
                    value={data.age}
                    handleChange={handleChange}
                    name='age'
                    title='Age'
                    type='text'
                />
                <SelectComponent
                    value={data.course}
                    handleChange={handleChange}
                    name='course'
                    title='Select Course'
                    type='select'
                />
                <SubmitContainer>
                    <p>
                        By registering here, you agree to our{" "}
                        <LinkContent>Terms & Condition</LinkContent> and that
                        you have read our{" "}
                        <LinkContent>Privacy Policy</LinkContent>
                    </p>
                    <ButtonWrapper onClick={handleSubmit}>
                        <span></span>
                        <button>Get Demo</button>
                    </ButtonWrapper>
                </SubmitContainer>
            </FormContainer>
        </Container>
    );
}

const Container = styled.div`
    padding: 20px 100px 20px 80px;
    margin-top: 120px;
    display: grid;
    grid-template-columns: 1fr 1.4fr;
    grid-gap: 40px;
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 20px 20px 20px;
    }
    @media screen and (max-width: 425px) {
        margin-top: 80px;
    }
`;
const CardContainer = styled.div`
    position: relative;
    padding: 80px 40px;
    height: 100%;
    min-height: 600px;
    max-height: 600px;
    width: 100%;
    background: rgba(251, 251, 252, 0.25);
    box-shadow: 4px 4px 28px rgba(25, 25, 25, 0.14);
    backdrop-filter: blur(8px);
    border-radius: 4px;
    > h1 {
        margin-bottom: 50px;
        font-size: 36px;
        line-height: 36px;
        font-weight: 600;
        letter-spacing: -0.01em;
    }
    > p {
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        /* or 125% */
        width: 305px;
    }
    @media screen and (max-width: 768px) {
        max-height: 400px;
        min-height: 400px;
        padding: 30px 40px;
        > h1 {
            font-size: 30px;
            margin-bottom: 30px;
        }
    }
    @media screen and (max-width: 425px) {
        max-height: 400px;
        min-height: 400px;
        padding: 30px 20px;
        > h1 {
            font-size: 24px;
            line-height: 26px;
            margin-bottom: 30px;
        }
        > p {
            font-size: 14px;
            line-height: 16px;
            width: auto;
        }
    }
`;
const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
`;
const SubmitContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    > p {
        width: 340px;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
    }
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        > p {
            position: absolute;
            left: 25px;
            margin-bottom: 20px;
        }
    }
    @media screen and (max-width: 425px) {
        > p {
            width: 300px;
        }
    }
`;

const LinkContent = styled.a`
    text-decoration: underline;
    color: blue;
`;

const ButtonWrapper = styled.div`
    position: relative;
    > span {
        border: 2px solid #191919;
        border-radius: 4px;
        width: 100%;
        height: 100%;
        z-index: 10;
        position: absolute;
        margin-top: -6px;
        margin-left: -6px;
        transition: all 0.2s ease-in;
        cursor: pointer;
        :hover {
            margin-top: 0px;
            margin-left: 0px;
        }
    }
    > button {
        background: #00ffff;
        border-radius: 4px;
        font-weight: bold;
        font-size: 17px;
        line-height: 23px;
        text-align: center;
        letter-spacing: -0.231818px;
        padding: 13px 30px;
        z-index: -1;
    }
    @media screen and (max-width: 768px) {
        margin-top: 55px;
    }
`;

const AgeSelector = styled.select`
    display: flex;
    flex-direction: column;
    > label {
        font-size: 15px;
        line-height: 12px;
        color: #191919;
        opacity: 0.6;
        margin-bottom: 10px;
        font-weight: 200;
        margin-left: 5px;
    }
    >input {
        padding: 12px;
        width: 90%;
        font-size: 18px;
        background: rgba(251, 251, 252, 0.25);
        opacity: 0.6;
        box-shadow: 5px 4px 18px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(8px);
        border: none;
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 8px;
    }
    @media screen and (max-width : 768px){
        >input{
            width: 100%;
        }
        margin-bottom: 30px;
    }
`;
