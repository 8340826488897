import styled from "styled-components";
import AboutCards from "../../Components/AboutCards/AboutCards";

import CEO from '../../Images/Founders/CEO.jpeg';
import CFO from '../../Images/Founders/CFO.jpeg';
import COO from '../../Images/Founders/coo.jpeg';
import SE from '../../Images/Founders/SE2.png';

import Footer from "../../Partials/Footer";
export default function About() {
    return (
        <Container>
            <AboutCards title='about' seco />
            <FounderContainer>
                <FounderTitle>
                    <h2>our founders</h2>
                    <BorderMargin />
                </FounderTitle>
                <FounderCardWrapper>
                    <FounderCard>
                        <ImageContainer>
                            <img src={CEO} alt='founder' />
                        </ImageContainer>
                        <CartTitle>
                            <h2>Lavish Somvanshi</h2>
                            <p>Founder and CEO</p>
                        </CartTitle>
                        <CartData>
                            Since the organization's inception in February 2021,
                            he has been working on building an institution of
                            the future by creating an environment of Interactive
                            online sessions that are far better than the status
                            quo.
                        </CartData>
                    </FounderCard>

                    <FounderCard>
                        <ImageContainer>
                            <img src={COO} alt='founder' />
                        </ImageContainer>
                        <CartTitle>
                            <h2>Mehul Bhandari</h2>
                            <p>Chief Operating Officer</p>
                        </CartTitle>
                        <CartData>
                            Started as an educator of coding and mathematics, he
                            has proven to be a vital member of the fanatic SFS
                            team, working towards creating a classroom
                            environment every student can embrace.
                        </CartData>
                    </FounderCard>

                    <FounderCard>
                        <ImageContainer>
                            <img src={CFO} alt='founder' />
                        </ImageContainer>
                        <CartTitle>
                            <h2>Aryan Seth</h2>
                            <p>Executive Vice President and CFO</p>
                        </CartTitle>
                        <CartData>
                            Joined the organization at its trying times, he has
                            established himself as the backbone of the financial
                            and policy development of SFS.
                        </CartData>
                    </FounderCard>

                    <FounderCard>
                        <ImageContainer>
                            <img src={SE} alt='founder' />
                        </ImageContainer>
                        <CartTitle>
                            <h2>Ananya Nautiyal</h2>
                            <p>Senior Vice President</p>
                        </CartTitle>
                        <CartData>
                            Ananya has established herself as a headhunter by
                            playing a critical role in setting up and managing
                            the recruitment and public relations department
                            which has led SFS to embark on a systematic approach
                            to running.
                        </CartData>
                    </FounderCard>
                </FounderCardWrapper>
            </FounderContainer>
            <Footer />
        </Container>
    );
}

const Container = styled.div`
    margin-top: 140px;
    overflow-x: hidden;
`;

const FounderContainer = styled.div`
    margin-top: 100px;
    margin-bottom: 150px;
    cursor: default;
`;

const FounderTitle = styled.div`
    display: inline-block;
    > h2 {
        font-weight: bold;
        font-size: 90px;
        line-height: 96px;
        color: #191919;
        text-transform: uppercase;
        opacity: 0.4;
        margin-left: 50px;
    }
    @media screen and (max-width: 768px) {
        > h2 {
            font-size: 60px;
            margin-left: 10px;
        }
    }

    @media screen and (max-width: 425px) {
        > h2 {
            font-size: 45px;
            margin-left: 10px;
        }
    }
`;

const BorderMargin = styled.div`
    margin-right: -20px;
    height: 56px;
    background-color: #9e23ff;
    margin-top: -40px;
    @media screen and (max-width: 768px) {
        height: 36px;
    }
`;

const FounderCardWrapper = styled.div`
    margin-top: 60px;
    padding: 10px 50px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    @media screen and (max-width: 500px) {
        padding: 0px;
    }
`;

const FounderCard = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 360px;
    width: 506px;
    padding: 30px;
    background: rgba(251, 251, 252, 0.25);
    box-shadow: 4px 4px 28px rgba(25, 25, 25, 0.14);
    backdrop-filter: blur(8px);
    border-radius: 12px;
    margin-bottom: 80px;
    @media screen and (max-width: 500px) {
        max-width: 100%;
    }
`;

const ImageContainer = styled.div`
    position: absolute;
    top: 20px;
    right: -30px;
    width: 100%;
    max-width: 140px;
    height: 100%;
    max-height: 120px;
    border-radius: 20px;
    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
    }
    @media screen and (max-width: 500px) {
        top: 90%;
        right: 10px;
    }
`;

const CartData = styled.div`
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 32px;
    /* or 200% */

    letter-spacing: 0.04em;

    /* Dark */

    color: #191919;
`;

const CartTitle = styled.div`
    margin-bottom: 40px;
    > h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 65px;
        color: #191919;
        opacity: 0.9;
    }
    > p {
        font-weight: bold;
        font-size: 21px;
        line-height: 33px;
        color: #191919;
        opacity: 0.7;
    }
`;
