import styled from "styled-components";
import { device, devicesSmall } from "../../../devices";

export default function Divider() {
    return (
        <Wrapper>
            <Container>
                <ContentWrapper>
                    <p>Discover the joy of online learning</p>
                    <Rectangles top = '102px' left = '35px' color = '#FFE500' />
                </ContentWrapper>
                <ContentMargins />
                <ContentWrapper>
                    <p>Courses to fit in all your child’s needs</p>
                    <Rectangles top = '35px' left = '35px' color = '#9E23FF' />
                </ContentWrapper>
                <ContentMargins />
                <ContentWrapper style = {{marginBottom : '20px'}} >
                    <p>A new era of personalised learning</p>
                    <Rectangles top = '88px' left = '35px' color = '#00FFFF' />
                </ContentWrapper>
            </Container>
        </Wrapper>
    )
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease-in-out;
    background-color: rgba(251, 251, 252, 0.6);
    margin-bottom: 50px;
    margin-top: -230px;
    @media ${device.tablet}
    {
        margin-top: 0px;
    }
    @media ${device.mobileS}
    {
        margin-top: -10px;
    }
    @media ${devicesSmall.laptopL}
    {
        margin-right: 40px;
        align-items:flex-end;
    }
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 98%;
    z-index:3;
    max-width: 1093px;
    background-color: rgba(251, 251, 252, 0.4);
    box-shadow: -6px -6px 24px rgba(25, 25, 25, 0.1), 6px 6px 24px rgba(25, 25, 25, 0.03);
    backdrop-filter: blur(1px);
    border-radius: 8px;
    @media ${device.tablet}
    {
        flex-direction: column;
    }
`;


const ContentWrapper = styled.div`
    position: relative;
    text-align: center;
    padding: 50px 20px;
    >p{
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
    }
    @media ${device.tablet}
    {
        margin-bottom: -20px;
        padding:30px 20px;
    }
`;

const ContentMargins = styled.div`
    width: 3px;
    height:100px;
    border: 1px solid #FBFBFC;
    background-color: #FBFBFC;
`;

const Rectangles = styled.div`
    position: absolute;
    background-color: ${props => props.color};
    width: 172px;
    height: 80px;
    z-index:-1;
    top: ${props => props.top};
    left: ${props => props.left};
`;

