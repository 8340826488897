import Hero1 from '../Images/HeroImages/hero1.png';
import Hero2 from '../Images/HeroImages/hero2.png';
import Hero3 from '../Images/HeroImages/hero3.png';

export const HeroPageData = [
    {
        id : 1,
        'title' : 'Start From Scratch is a new way to learn Coding, English, Mathematics Online.',
        'img' : Hero1,
    },
    {
        id : 2,
        'title' : 'Take a demo class now and get your learning curve assesed by an expert.',
        'img' : Hero2,
    },
    {
        id : 3,
        'title' : 'Schedule your online classes in your free hours after school.',
        'img' : Hero3,
    }, 
]