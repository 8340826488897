import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import useWindowDimensions from "../Hooks/useWindowDimensions";
import Logo from "../Images/Main/logo_black.png";
import "./Header.css";
import { Link } from "react-scroll";

export default function Header() {
    let location = useLocation();
    const history = useHistory();
    const { width } = useWindowDimensions();
    const [header, setHeader] = useState("header");
    const [h, setH] = useState("10px");
    const [height1, setHeight1] = useState("10px");
    const [height2, setHeight2] = useState("10px");
    const listenScrollEvent = (event) => {
        if (window.scrollY < 73) {
            return setHeader("header");
        } else if (window.scrollY > 70) {
            return setHeader("header2");
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);
        return () => window.removeEventListener("scroll", listenScrollEvent);
    }, []);
    const handleScroll = () => {
        if( location.pathname === '/' ) return;
        else return history.push('/');
    }
    return (
        <div className={header}>
            <LogoContainer onClick = {() => history.push('/')} >
                <img src={Logo} alt='err' />
            </LogoContainer>
            <LinksContainer>
                <Link onClick = {handleScroll} to='courses' spy={true} smooth={true}>
                    <TitleContainer
                        onMouseEnter={() => setH("23px")}
                        onMouseLeave={() => setH("10px")}>
                        <p> Courses </p>
                        <LinkBorder height={h} color='#36A6DB' />
                    </TitleContainer>
                </Link>

                <Link onClick = {handleScroll} to='reviews' spy={true} smooth={true}>
                    <TitleContainer
                        onMouseEnter={() => setHeight1("23px")}
                        onMouseLeave={() => setHeight1("10px")}>
                        <p> Reviews </p>
                        <LinkBorder height={height1} color='#00FFFF' />
                    </TitleContainer>
                </Link>
                <TitleContainer
                    onMouseEnter={() => setHeight2("23px")}
                    onMouseLeave={() => setHeight2("10px")}
                    onClick = {() => history.push('/about')}
                    >
                    <p> About </p>
                    <LinkBorder height={height2} color='#F58020' />
                </TitleContainer>
            </LinksContainer>
            <DemoButton onClick = {() => history.push('/demo')} >
                {width > 820 ? "Request a Free Demo" : "Free Demo"}
            </DemoButton>
        </div>
    );
}

const LogoContainer = styled.div`
    width: 80px;
    height: 65px;
    cursor: pointer;
    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media screen and (max-width: 900px) {
        width: 70px;
        height: 55px;
    }
`;
const LinksContainer = styled.div`
    display: flex;
    align-items: center;
    transform: translateX(11%);
    @media screen and (max-width: 1024px) {
        transform: translateX(6%);
    }
    @media screen and (max-width: 820px) {
        display: none;
    }
`;
const TitleContainer = styled.div`
    position: relative;
    padding: 3px 5px;
    margin: 2px 40px;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
    > p {
        text-align: center;
        margin-left: 1.3px;
    }
    @media screen and (max-width: 1024px) {
        margin: 2px 30px;
    }
`;
const LinkBorder = styled.div`
    width: 90%;
    background: ${(props) => props.color};
    height: ${(props) => props.height};
    position: absolute;
    bottom: 4px;
    z-index: -1;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
    opacity: 0.6;
`;

const DemoButton = styled.div`
    background-color: #ffe500;
    color: black;
    letter-spacing: 1px;
    font-family: var(--font-main);
    font-weight: 400;
    font-size: 18px;
    text-transform: uppercase;
    border: none;
    outline: none;
    padding: 8px 18px;
    border-radius: 10px;
    box-shadow: 0xp 2px 3px rgba(255, 255, 255, 0.4);
    @media screen and (max-width: 900px) {
        font-size: 15px;
        padding: 5px 12px;
        transform: translateY(-10%);
        font-weight: 600;
    }
    cursor: pointer;
`;
