import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Accordian from "../../Components/Accordian/Accordian";
import PricingCard from "../../Components/PricingCard/PricingCard";
import { CourseData } from "../../Data/CourseData";
import { PrincingData } from "../../Data/pricingData";
import TickImg from "../../Images/Courses/Tick.svg";
import Footer from "../../Partials/Footer";

export default function CourseView() {
    let {slug} = useParams();
    const [data, setData] = useState([]);
    const [value, setValue] = useState('INR');
    const [idx, setIdx] = useState(0);
    useEffect(() => {
        const findData = () => {
            let values = CourseData.filter( item => item.slug === slug ); // eslint-disable-next-line
            setData(values[0]); // eslint-disable-next-line
        }
        findData();  // eslint-disable-next-line
    }, []);

    const handleChange = (e) => {
        const value = e.target.value;
        setValue(value);
        if ( value === 'INR' ) setIdx(0);
        else if( value === 'AED' ) setIdx(1);
        else setIdx(2);
    }
    return (
        <>
            <Container>
                <Heading>
                    <h1>{data?.name}</h1>
                    <HeadingImageContainer></HeadingImageContainer>
                </Heading>

                <DetailsContainer>
                    <Description>
                        <h3>Description</h3>
                        <p>
                            {data?.desc}
                        </p>
                    </Description>
                    <Learning>
                        <h1>Key Learning Outcomes</h1>
                        {data?.outcome?.map( item => (
                                <Points key = {item?.id} >
                                    <TickImage>
                                        <img src={TickImg} alt='err' />{" "}
                                    </TickImage>
                                    <p>{item?.value}</p>
                                </Points>
                        ) )}
                    </Learning>
                </DetailsContainer>

                <LessonsContainer>
                    <Title>
                        <h2>{slug === 'neon' ? 'Courses' : 'Lessons'}</h2>
                    </Title>
                    {data?.lessons?.map( item => <Accordian key = {item?.id} {...item} />)}
                    <AccordiansContainer></AccordiansContainer>
                </LessonsContainer>

                <PricingContainer>
                    <Title>
                        <h2>Pricing</h2>
                    </Title>
                    <PriceCardsContainer>
                        {PrincingData?.map( item => <Wrapper key = {item?.id} ><PricingCard {...item} demo = {value} price = {item?.cost[idx]} /></Wrapper>  )}
                    </PriceCardsContainer>

                    <CountrySelect value = {value} onChange = {handleChange} name = 'country' >
                        <option value= 'INR'> INR </option>
                        <option value='AED'> AED </option>
                        <option value='USD'> USD </option>
                    </CountrySelect>

                </PricingContainer>
            </Container>
            <Footer id='contact' />
        </>
    );
}

const Container = styled.div`
    padding: 20px 3% 20px 3%;
    margin-top: 140px;
    margin-bottom: 120px;
`;

const Heading = styled.div`
    > h1 {
        font-size: 72px;
        line-height: 96px;
        color: #191919;
    }
`;
const HeadingImageContainer = styled.div``;
const DetailsContainer = styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
`;
const Description = styled.div`
    flex: 1.3;
    padding: 20px 40px;
    background: rgba(251, 251, 252, 0.15);
    box-shadow: 4px 4px 24px rgba(25, 25, 25, 0.08);
    backdrop-filter: blur(4px);
    border-radius: 12px;
    margin: 5px 5% 0px 0px;
    > h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #191919;
        margin-bottom: 20px;
    }
    > p {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #191919;
    }
`;
const Learning = styled.div`
    flex: 1;
    padding: 20px 40px;
    background: rgba(251, 251, 252, 0.15);
    box-shadow: 4px 4px 24px rgba(25, 25, 25, 0.08);
    backdrop-filter: blur(4px);
    border-radius: 12px;
    > h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #191919;
        margin-bottom: 20px;
    }
`;
const Points = styled.div`
    display: flex;
    margin-bottom: 10px;
    > p {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #191919;
        margin-left: 10px;
    }
`;
const TickImage = styled.div``;
const LessonsContainer = styled.div`
    margin-top: 80px;
`;
const Title = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #191919;
`;

const AccordiansContainer = styled.div``;
const PricingContainer = styled.div`
    margin-top: 80px;
    position: relative;
`;
const PriceCardsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`;
const Wrapper = styled.div`
    margin: 0px 20px;
`;


const CountrySelect = styled.select`
    position: absolute;
    top: 10px;
    right: 40px;
    font-size: 18px;
    border-radius: 5px;
    padding: 2px 12px;
    text-align: center;
    border: 2px solid blueviolet;
    cursor: pointer;
    text-transform: capitalize;
`;
