import styled from "styled-components";
import "./App.css";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Header from "./Partials/Header";
import About from "./Pages/About/About";
import Demo from "./Pages/Demo/Demo";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import Course from "./Pages/Course/Course";
import useWindowDimensions from "./Hooks/useWindowDimensions";
import CourseView from "./Pages/Course/CourseView";

import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";

function App() {
    const { width } = useWindowDimensions();
    return (
        <Container>
            <ScrollToTop>
                <Header />
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route
                        path='/course/:slug'
                        render={(props) =>
                            width > 800 ? <CourseView /> : <Course />
                        }
                    />
                    <Route path='/demo' component={Demo} />
                    <Route path='/about' component={About} />
                    <Route path='*' render={(props) => <Redirect to='/' />} />
                </Switch>
            </ScrollToTop>
            <ToastContainer
                position='top-right'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Container>
    );
}

export default App;

const Container = styled.div``;
