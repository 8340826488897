import { v4 as uuidv4 } from "uuid";
import CodingImg from '../Images/CourseImages/coding.png';
import MathsImg from '../Images/CourseImages/maths.png';
import EngImg from '../Images/CourseImages/eng.png';


export const CourseData = [
    {
        id: uuidv4(),
        department: "Coding",
        name: "Neon",
        slug:'neon',
        imgUrl : CodingImg,
        desc: "From introductory courses to acquaint beginners and young kids with different programming languages and tools to specially developed and allocated systems for teaching advanced learners web development, app development, advanced Python, file handling, Python GUI, etc. ",
        outcome: [
            {
                id: uuidv4(),
                value: "Getting acquainted with computer programming",
            },
            {
                id: uuidv4(),
                value: "Working with python and other programming languages",
            },
            {
                id: uuidv4(),
                value: "Web development, App development, Managing SQL databases",
            },
            {
                id: uuidv4(),
                value: "Introduction to AI, ML, and Data Science",
            },
            {
                id: uuidv4(),
                value: "Advanced Programming in Python",
            },
        ],
        lessons: [
            {
                id: uuidv4(),
                title: "Krypton",
                values: [
                    {
                        id: uuidv4(),
                        value: "Scratch",
                    },
                    {
                        id: uuidv4(),
                        value: "Turtle Academy",
                    },
                    {
                        id: uuidv4(),
                        value: "Python",
                    },
                    {
                        id: uuidv4(),
                        value: "Introduction to various other programming languages",
                    },
                    {
                        id: uuidv4(),
                        value: "Introduction to AI, ML and Data Scienc",
                    },
                ],
            },
            {
                id: uuidv4(),
                title: "XENON",
                values: [
                    {
                        id: uuidv4(),
                        value: "HTML",
                    },
                    {
                        id: uuidv4(),
                        value: "CSS",
                    },
                    {
                        id: uuidv4(),
                        value: "Javascript",
                    },
                    {
                        id: uuidv4(),
                        value: "My SQL",
                    },
                    {
                        id: uuidv4(),
                        value: "Advanced Programming in Python",
                    },
                    {
                        id: uuidv4(),
                        value: "Further frontend and introduction to php and bootstrap",
                    },
                    {
                        id: uuidv4(),
                        value: "Introduction to AI, ML",
                    },
                    {
                        id: uuidv4(),
                        value: "Introduction to FLUTTER and dart programming",
                    },
                    {
                        id: uuidv4(),
                        value: "Engineering level Web Development project",
                    },
                ],
            },
            {
                id: uuidv4(),
                title: "RADON",
                values: [
                    {
                        id: uuidv4(),
                        value: "Python",
                    },
                    {
                        id: uuidv4(),
                        value: "Introduction to JSON",
                    },
                    {
                        id: uuidv4(),
                        value: "Game development in Python",
                    },
                    
                    {
                        id: uuidv4(),
                        value: "App Development",
                    },
                    {
                        id: uuidv4(),
                        value: "Introduction to Data Science and  NumPy modules",
                    },
                    {
                        id: uuidv4(),
                        value: "Introduction to Geometry Manager",
                    },
                ],
            },
        ],
    },

    {
        id: uuidv4(),
        department: "Mathematics",
        name: "Infinity",
        slug:'infinity',
        imgUrl : MathsImg,
        desc: "From introductory courses to acquaint beginners and young kids with different programming languages and tools to specially developed and allocated systems for teaching advanced learners web development, app development, advanced Python, file handling, Python GUI, etc. ",
        outcome: [
            {
                id: uuidv4(),
                value: "Prepare for national and international Olympiads",
            },
            {
                id: uuidv4(),
                value: "Foundation for SAT, IIT-JEE, GMAT, ACT  and other competitive exams",
            },
        ],
        lessons: [
            {
                id: uuidv4(),
                title: "Number System",
                values: [
                    {
                        id: uuidv4(),
                        value: "Playing with numbers",
                    },
                    {
                        id: uuidv4(),
                        value: "Square and square roots",
                    },
                ],
            },
            {
                id: uuidv4(),
                title: "Algebra",
                values: [
                    {
                        id: uuidv4(),
                        value: "Linear equation in one variable",
                    },
                    {
                        id: uuidv4(),
                        value: "Linear equation in two variables",
                    },
                    {
                        id: uuidv4(),
                        value: "Inequalities",
                    },
                    {
                        id: uuidv4(),
                        value: "Quadratic Equations",
                    },
                    {
                        id: uuidv4(),
                        value: "Polynomials",
                    },
                    {
                        id: uuidv4(),
                        value: "Exponents and Power",
                    },
                ],
            },
            {
                id: uuidv4(),
                title: "Geometry",
                values: [
                    {
                        id: uuidv4(),
                        value: "Lines",
                    },
                    {
                        id: uuidv4(),
                        value: "Angles",
                    },
                    {
                        id: uuidv4(),
                        value: "Triangles",
                    },
                    
                    {
                        id: uuidv4(),
                        value: "Quadrilaterals",
                    },
                    {
                        id: uuidv4(),
                        value: "Circles",
                    },
                ],
            },
            {
                id: uuidv4(),
                title: "Mensuration",
                values: [
                    {
                        id: uuidv4(),
                        value: "Area",
                    },
                    {
                        id: uuidv4(),
                        value: "Volume",
                    },
                    {
                        id: uuidv4(),
                        value: "Theorems Axioms and Postulates",
                    },
                ],
            },
            {
                id: uuidv4(),
                title: "Probability",
                values: [
                    {
                        id: uuidv4(),
                        value: "Permutation and Combination",
                    },
                    {
                        id: uuidv4(),
                        value: "Probability",
                    },
                ],
            },
        ],
    },

    {
        id: uuidv4(),
        department: "English",
        name: "Aurora",
        imgUrl : EngImg,
        slug:'aurora',
        desc: "Our courses have been designed keeping in mind the intricacies of modern-day communication. We have researched and hand-picked effective teaching methods that will help a child communicate effectively and hone their command of the English language. ",
        outcome: [
            {
                id: uuidv4(),
                value: "Prepare for standardised exams like TOEFL, IELTS",
            },
            {
                id: uuidv4(),
                value: "Publish a blog or an article",
            },
            {
                id: uuidv4(),
                value: "Participate in MUNs",
            },
            {
                id: uuidv4(),
                value: "Read and examine books and novels to get a better insight into writing styles",
            },
        ],
        lessons: [
            {
                id: uuidv4(),
                title: "Spoken English",
                values: [
                    {
                        id: uuidv4(),
                        value: "Public Speaking",
                    },
                    {
                        id: uuidv4(),
                        value: "Listening and Repeating Synchronisation",
                    },
                    {
                        id: uuidv4(),
                        value: "Spontaneous story-telling",
                    },
                    {
                        id: uuidv4(),
                        value: "IELTS audio sessions",
                    },
                    {
                        id: uuidv4(),
                        value: "E-series",
                    },
                    {
                        id: uuidv4(),
                        value: "Debate Session",
                    },
                    {
                        id: uuidv4(),
                        value: "R & T",
                    },
                ],
            },
            {
                id: uuidv4(),
                title: "Vocabulary",
                values: [
                    {
                        id: uuidv4(),
                        value: "Power words",
                    },
                    {
                        id: uuidv4(),
                        value: "Idioms and Phrases",
                    },
                    {
                        id: uuidv4(),
                        value: "Revision and Test",
                    },
                    {
                        id: uuidv4(),
                        value: "Literary devices",
                    },
                ],
            },
            {
                id: uuidv4(),
                title: "Listening skills",
                values: [
                    {
                        id: uuidv4(),
                        value: "English Songs",
                    },
                    {
                        id: uuidv4(),
                        value: "IELTS dialogue sessions",
                    },
                    {
                        id: uuidv4(),
                        value: "Video dialogues",
                    },
                    {
                        id: uuidv4(),
                        value: "R & T",
                    },
                ],
            },
            {
                id: uuidv4(),
                title: "Creative writing",
                values: [
                    {
                        id: uuidv4(),
                        value: "Expressing Opinions",
                    },
                    {
                        id: uuidv4(),
                        value: "Story writing via prompts",
                    },
                    {
                        id: uuidv4(),
                        value: "Expressing views",
                    },
                    {
                        id: uuidv4(),
                        value: "R & T",
                    },
                ],
            },
            {
                id: uuidv4(),
                title: "Grammar",
                values: [
                    {
                        id: uuidv4(),
                        value: "Tenses",
                    },
                    {
                        id: uuidv4(),
                        value: "Voice",
                    },
                    {
                        id: uuidv4(),
                        value: "Speech",
                    },
                ],
            },
            {
                id: uuidv4(),
                title: "Revision & Test",
                values: [
                    {
                        id: uuidv4(),
                        value: "R & T / English Spoken",
                    },
                    {
                        id: uuidv4(),
                        value: "R & T (Listening)",
                    },
                    {
                        id: uuidv4(),
                        value: "R & T (Creative, Vocabulary, Grammar)",
                    },
                ],
            },
        ],
    },
];


// export const CourseData = [
//     {
//         id: uuidv4(),
//         department: "Coding",
//         name: "Neon",
//         imgUrl : CodingImg,
//         desc: "Meticulously designed keeping in mind the five strands of mathematical proficiency, including conceptual understanding, Procedural Fluency, Strategic competence, Adaptive reasoning, and productive disposition, throughout the course, our educators strive to adapt and deliver the most delicate and essential concepts of Algebra, Geometry, Arithmetic.",
//         outcome: [
//             {
//                 id: uuidv4(),
//                 value: "Prepare for national and international Olympiads",
//             },
//             {
//                 id: uuidv4(),
//                 value: "Foundation for SAT, IIT-JEE, GMAT, ACT  and other competitive exams",
//             },
//         ],
//         lessons: [
//             {
//                 id: uuidv4(),
//                 title: "Number System",
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Playing with numbers",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Square and square roots",
//                     },
//                 ],
//             },
//             {
//                 id: uuidv4(),
//                 title: "Algebra",
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Linear equation in one variable",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Linear equation in two variables",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Inequalities",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Quadratic Equations",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Polynomials",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Exponents and Power",
//                     },
//                 ],
//             },
//             {
//                 id: uuidv4(),
//                 title: "Geometry-",
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Lines",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "angles",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "triangles",
//                     },
                    
//                     {
//                         id: uuidv4(),
//                         value: "quadrilaterals",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "circles",
//                     },
//                 ],
//             },
//             {
//                 id: uuidv4(),
//                 title: "Mensuration",
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Area",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Volume",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Theorems Axioms and Postulates",
//                     },
//                 ],
//             },
//             {
//                 id: uuidv4(),
//                 title: "Probability",
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Permutation and Combination",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Probability",
//                     },
//                 ],
//             },
//         ],
//         pricing: [
//             {
//                 id: uuidv4(),
//                 type: "1",
//                 session: 24,
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, dolor consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur",
//                     },
//                 ],
                
//             },
            
//         ],
//     },
//     {
//         id: uuidv4(),
//         department: "Mathematics",
//         name: "Infinity",
//         imgUrl : MathsImg ,
//         desc: "Meticulously designed keeping in mind the five strands of mathematical proficiency, including conceptual understanding, Procedural Fluency, Strategic competence, Adaptive reasoning, and productive disposition, throughout the course, our educators strive to adapt and deliver the most delicate and essential concepts of Algebra, Geometry, Arithmetic.",
//         outcome: [
//             {
//                 id: uuidv4(),
//                 value: "Prepare for national and international Olympiads",
//             },
//             {
//                 id: uuidv4(),
//                 value: "Foundation for SAT, IIT-JEE, GMAT, ACT  and other competitive exams",
//             },
//         ],
//         lessons: [
//             {
//                 id: uuidv4(),
//                 title: "Number System",
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Playing with numbers",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Square and square roots",
//                     },
//                 ],
//             },
//             {
//                 id: uuidv4(),
//                 title: "Algebra",
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Linear equation in one variable",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Linear equation in two variables",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Inequalities",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Quadratic Equations",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Polynomials",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Exponents and Power",
//                     },
//                 ],
//             },
//             {
//                 id: uuidv4(),
//                 title: "Geometry-",
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Lines",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "angles",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "triangles",
//                     },
                    
//                     {
//                         id: uuidv4(),
//                         value: "quadrilaterals",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "circles",
//                     },
//                 ],
//             },
//             {
//                 id: uuidv4(),
//                 title: "Mensuration",
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Area",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Volume",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Theorems Axioms and Postulates",
//                     },
//                 ],
//             },
//             {
//                 id: uuidv4(),
//                 title: "Probability",
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Permutation and Combination",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Probability",
//                     },
//                 ],
//             },
//         ],
//         pricing: [
//             {
//                 id: uuidv4(),
//                 type: "1",
//                 session: 24,
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, dolor consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur",
//                     },
//                 ],
//                 price: [
//                     {
//                         id: uuidv4(),
//                         demo: "INR",
//                         value: "24000",
//                     },
//                     {
//                         id: uuidv4(),
//                         demo: "AED",
//                         value: "24000",
//                     },
//                     {
//                         id: uuidv4(),
//                         demo: "USD",
//                         value: "24000",
//                     },
//                 ],
//             },
//             {
//                 id: uuidv4(),
//                 type: "2",
//                 session: 24,
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, dolor consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur",
//                     },
//                 ],
//                 price: [
//                     {
//                         id: uuidv4(),
//                         demo: "INR",
//                         value: "24000",
//                     },
//                     {
//                         id: uuidv4(),
//                         demo: "AED",
//                         value: "24000",
//                     },
//                     {
//                         id: uuidv4(),
//                         demo: "USD",
//                         value: "24000",
//                     },
//                 ],
//             },
//             {
//                 id: uuidv4(),
//                 type: "3",
//                 session: 24,
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, dolor consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur",
//                     },
//                 ],
//                 price: [
//                     {
//                         id: uuidv4(),
//                         demo: "INR",
//                         value: "24000",
//                     },
//                     {
//                         id: uuidv4(),
//                         demo: "AED",
//                         value: "24000",
//                     },
//                     {
//                         id: uuidv4(),
//                         demo: "USD",
//                         value: "24000",
//                     },
//                 ],
//             },
//         ],
//     },

//     {
//         id: uuidv4(),
//         department: "English",
//         name: "Aurora",
//         imgUrl : EngImg,
//         desc: "Meticulously designed keeping in mind the five strands of mathematical proficiency, including conceptual understanding, Procedural Fluency, Strategic competence, Adaptive reasoning, and productive disposition, throughout the course, our educators strive to adapt and deliver the most delicate and essential concepts of Algebra, Geometry, Arithmetic.",
//         outcome: [
//             {
//                 id: uuidv4(),
//                 value: "Prepare for national and international Olympiads",
//             },
//             {
//                 id: uuidv4(),
//                 value: "Foundation for SAT, IIT-JEE, GMAT, ACT  and other competitive exams",
//             },
//         ],
//         lessons: [
//             {
//                 id: uuidv4(),
//                 title: "Number System",
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Playing with numbers",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Square and square roots",
//                     },
//                 ],
//             },
//             {
//                 id: uuidv4(),
//                 title: "Algebra",
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Linear equation in one variable",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Linear equation in two variables",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Inequalities",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Quadratic Equations",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Polynomials",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Exponents and Power",
//                     },
//                 ],
//             },
//             {
//                 id: uuidv4(),
//                 title: "Geometry-",
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Lines",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "angles",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "triangles",
//                     },
                    
//                     {
//                         id: uuidv4(),
//                         value: "quadrilaterals",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "circles",
//                     },
//                 ],
//             },
//             {
//                 id: uuidv4(),
//                 title: "Mensuration",
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Area",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Volume",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Theorems Axioms and Postulates",
//                     },
//                 ],
//             },
//             {
//                 id: uuidv4(),
//                 title: "Probability",
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Permutation and Combination",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Probability",
//                     },
//                 ],
//             },
//         ],
//         pricing: [
//             {
//                 id: uuidv4(),
//                 type: "1",
//                 session: 24,
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, dolor consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur",
//                     },
//                 ],
//                 price: [
//                     {
//                         id: uuidv4(),
//                         demo: "INR",
//                         value: "24000",
//                     },
//                     {
//                         id: uuidv4(),
//                         demo: "AED",
//                         value: "24000",
//                     },
//                     {
//                         id: uuidv4(),
//                         demo: "USD",
//                         value: "24000",
//                     },
//                 ],
//             },
//             {
//                 id: uuidv4(),
//                 type: "2",
//                 session: 24,
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, dolor consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur",
//                     },
//                 ],
//                 price: [
//                     {
//                         id: uuidv4(),
//                         demo: "INR",
//                         value: "24000",
//                     },
//                     {
//                         id: uuidv4(),
//                         demo: "AED",
//                         value: "24000",
//                     },
//                     {
//                         id: uuidv4(),
//                         demo: "USD",
//                         value: "24000",
//                     },
//                 ],
//             },
//             {
//                 id: uuidv4(),
//                 type: "3",
//                 session: 24,
//                 values: [
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, dolor consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur",
//                     },
//                     {
//                         id: uuidv4(),
//                         value: "Lorem ipsum dolor sit amet, consectetur",
//                     },
//                 ],
//                 price: [
//                     {
//                         id: uuidv4(),
//                         demo: "INR",
//                         value: "24000",
//                     },
//                     {
//                         id: uuidv4(),
//                         demo: "AED",
//                         value: "24000",
//                     },
//                     {
//                         id: uuidv4(),
//                         demo: "USD",
//                         value: "24000",
//                     },
//                 ],
//             },
//         ],
//     },

// ];
