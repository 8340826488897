import styled from "styled-components";
import BackImg from "../../Images/Courses/heading.png";
import TickImg from "../../Images/Courses/Tick.svg";
import { useEffect, useState } from "react";
import Accordian from "../../Components/Accordian/Accordian";
import PricingCard from "../../Components/PricingCard/PricingCard";
import LeftButton from "../../Images/Courses/LeftButton.svg";
import RightButton from "../../Images/Courses/RightButton.svg";
import Footer from "../../Partials/Footer";
import { useParams } from "react-router";
import { CourseData } from "../../Data/CourseData";
import { PrincingData } from "../../Data/pricingData";
export default function Course() {
    let {slug} = useParams();
    const [data, setData] = useState([]);
    const [value, setValue] = useState('INR');
    const [idx, setIdx] = useState(0);
    const [crr, setCrr] = useState(0);
    useEffect(() => {
        const findData = () => {
            let values = CourseData.filter( item => item.slug === slug ); // eslint-disable-next-line
            setData(values[0]);
        }
        findData(); // eslint-disable-next-line
    }, []);
    const handleChange = (e) => {
        const value = e.target.value;
        setValue(value);
        if ( value === 'INR' ) setIdx(0);
        else if( value === 'AED' ) setIdx(1);
        else setIdx(2);
    }
    return (
        <>
            <Container>
                <Heading img={BackImg}>{data?.name}</Heading>
                <Desc>
                    <Title>Description</Title>
                    <Content>
                        {data?.desc}
                    </Content>
                </Desc>

                <Outcomes>
                    <Title>Key Learning Outcomes</Title>
                    <Content>
                        {data?.outcome?.map( item => (
                                <Point key = {item?.id} >
                                    <img src={TickImg} alt='err' />
                                    <p>{item?.value}</p>
                                </Point>
                        ) )}
                    </Content>
                </Outcomes>

                <Lessons>
                    <Head>Lessons</Head>
                    <AccordianContainer>
                        {data?.lessons?.map( item => <Accordian key = {item?.id} {...item} /> )}
                    </AccordianContainer>

                </Lessons>

                <Pricing>
                    <DirectionButton>
                        <img
                            src={RightButton}
                            onClick={() =>
                                setCrr(crr - 1 < 0 ? 0 : (crr - 1) % 3)
                            }
                            alt='err'
                        />
                        <img
                            src={LeftButton}
                            onClick={() => setCrr((crr + 1) % 3)}
                            alt='err'
                        />
                    </DirectionButton>
                    <Head>
                        <p>Pricing</p>

                        <CountrySelect value = {value} onChange = {handleChange} name = 'country' >
                            <option value= 'INR'> INR </option>
                            <option value='AED'> AED </option>
                            <option value='USD'> USD </option>
                        </CountrySelect>

                    </Head>
                    <PricingContainer>
                        {PrincingData?.map((item, index) => (
                            <Slide
                                key={index}
                                style={{
                                    marginLeft:
                                        index === 0
                                            ? `-${crr * 100}%`
                                            : undefined,
                                }}>
                                <PricingCard {...item} demo = {value} price = {item?.cost[idx]} />
                            </Slide>
                        ))}
                    </PricingContainer>
                </Pricing>
            </Container>
            <Footer />
        </>
    );
}

const Container = styled.div`
    margin-top: 120px;
    margin-bottom: 120px;
`;
const Heading = styled.div`
    background-image: url(${(props) => props.img});
    font-size: 36px;
    line-height: 42px;
    padding-left: 10px;
    font-weight: 600;
`;

const Desc = styled.div`
    background: rgba(251, 251, 252, 0.15);
    box-shadow: 4px 4px 24px rgba(25, 25, 25, 0.1);
    backdrop-filter: blur(4px);
    padding: 18px 20px;
    @media screen and (max-width: 768px) {
        margin: 50px 0px;
        border-radius: 0px;
        border-style: solid;
        border-width: 2px 0px 0px 0px;
        border-image: linear-gradient(90deg, #9e23ff, #00ffff) 1;
    }
`;
const Title = styled.p`
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 12px;
`;
const Content = styled.p`
    font-size: 16px;
    font-weight: 300;
    line-height: 16px;
`;

const Outcomes = styled.div`
    background: rgba(251, 251, 252, 0.15);
    box-shadow: 4px 4px 24px rgba(25, 25, 25, 0.1);
    backdrop-filter: blur(4px);
    padding: 18px 20px;
    @media screen and (max-width: 768px) {
        margin: 50px 0px;
        border-radius: 0px;
        border-style: solid;
        border-width: 2px 0px 0px 0px;
        border-image: linear-gradient(90deg, #9e23ff, #00ffff) 1;
    }
`;

const Point = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    > p {
        margin-left: 5px;
    }
`;

const Lessons = styled.div`
    padding: 5px 15px;
`;
const Head = styled.div`
    display: flex;
    align-items: center;
    font-size: 30px;
    padding: 5px 0px 5px 15px;
    margin-top: 40px;
`;

const AccordianContainer = styled.div``;

const PricingContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
    @media screen and(min-width : 800){
        display: flex;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
    }
`;

const Pricing = styled.div`
    position: relative;
`;

const DirectionButton = styled.div`
    position: absolute;
    top: 20px;
    right: 0px;
    > img {
        margin-right: 40px;
        cursor: pointer;
    }
    @media screen and (min-width : 800px){
        display: none;
    }
`;

const Slide = styled.div`
    height: 100%;
    flex-shrink: 0;
    transition: 750ms all ease-in-out;
    @media screen and (min-width : 800px){
        margin: 0px 30px ;
    }
`;


const CountrySelect = styled.select`
    font-size: 14px;
    border-radius: 5px;
    padding: 2px 8px;
    text-align: center;
    border: 2px solid blueviolet;
    cursor: pointer;
    text-transform: capitalize;
    margin-top: 10px;
    margin-left: 10px;
`;