import styled from "styled-components";
import Courses from "../../Components/HeroPage/Courses/Courses";
import Divider from "../../Components/HeroPage/Divider/Divider";
import HeroDivider from "../../Components/HeroPage/HeroDivider/HeroDivider";
import HeroPage from "../../Components/HeroPage/HeroPage";
import Team from "../../Components/HeroPage/Team/Team";
import Testimonials from "../../Components/Testimonials/Testimonials";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import Footer from "../../Partials/Footer";

export default function Home() {
    const {width} = useWindowDimensions();
    return (
        <Container>
            <HeroPage />
            {width > 770 ? <Divider /> : <HeroDivider />}
            <Team />
            <Courses />
            <Testimonials />
            <Footer />
        </Container>
    )
}

const Container = styled.div``;
