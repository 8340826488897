import { useEffect, useState } from "react";
import styled from "styled-components";
import { HeroPageData } from "../../Data/HeroPageData";
export default function HeroPage() {
    const [crr, setCrr] = useState(0);
    useEffect(() => {
        const timer = setTimeout(() => {
            setCrr(crr + 1 > 2 ? 0 : crr + 1);
        }, 5000);
        return () => clearTimeout(timer);
    }, [crr]);
    return (
        <>
            <Container>
                {HeroPageData?.map((item, index) => (
                    <Slide
                        key={index}
                        style={{
                            marginLeft:
                                index === 0 ? `-${crr * 100}%` : undefined,
                            backgroundImage: `url(${item.img})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                        }}>
                        <DataContainer>
                            <p>{item.title}</p>
                        </DataContainer>
                    </Slide>
                ))}
                <Dots>
                    <Dot onClick = {() => setCrr(0)} active = { crr === 0 } />
                    <Dot onClick = {() => setCrr(1)} active = { crr === 1 } />
                    <Dot onClick = {() => setCrr(2)} active = { crr === 2 }/>
                </Dots>
            </Container>
        </>
    );
}

const Container = styled.div`
    display: flex;
    position: relative;
    height: 40vh;
    flex-wrap: nowrap;
    overflow-x: hidden;
    @media screen and (max-width: 425px) {
        height: 43vh;
    }
    @media screen and (min-width: 768px) {
        height: 100vh;
    }
`;

const Slide = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    flex-shrink: 0;
    background-image: ${(props) => props.backImg};
    transition: 750ms all ease-in-out;
`;

const DataContainer = styled.div`
    display: flex;
    align-items: center;
    justify-items: center;
    height: 100%;
    width: 100%;
    color: white;
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
    text-shadow: 2px 2px 4px rgba(25, 25, 25, 0.08);
    > p {
        width: 90%;
        margin-left: 20px;
        margin-top: 40px;
    }

    @media screen and (min-width: 768px) {
        font-size: 56px;
        line-height: 58px;
        > p {
            width: 90%;
            margin-top: -90px;
            margin-left: 50px;
        }
    }

    @media screen and (min-width: 1008px) {
        font-size: 56px;
        line-height: 58px;
        > p {
            width: 70%;
            margin-top: -160px;
            margin-left: 150px;
        }
    }
`;

const Dots = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 20px;
    width: 100%;
    @media screen and (min-width : 768px){
        bottom : 245px;
    }
`;
const Dot = styled.div`
    width: 80px;
    height: 3px;
    background-color: ${props => props.active ? 'white' : 'black'};
    margin: 1px 5px;
    transition: 750ms all ease-in-out;
    cursor: pointer;
`;
