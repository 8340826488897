import styled from "styled-components";

export default function InputComponent({title, type, value, name, handleChange}) {
    return (
        <Container>
            <label htmlFor={title}>{title}</label>
            <input value = {value} name = {name} onChange = {(e) => handleChange(e)} autoComplete = 'off' id= {title} type={type} />
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    > label {
        font-size: 15px;
        line-height: 12px;
        color: #191919;
        opacity: 0.6;
        margin-bottom: 10px;
        font-weight: 200;
        margin-left: 5px;
    }
    >input {
        padding: 12px;
        width: 90%;
        font-size: 18px;
        background: rgba(251, 251, 252, 0.25);
        opacity: 0.6;
        box-shadow: 5px 4px 18px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(20px);
        border: none;
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 8px;
    }
    @media screen and (max-width : 768px){
        >input{
            width: 100%;
        }
        margin-bottom: 30px;
    }
`;
