import styled from "styled-components";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { useHistory, useLocation } from "react-router";
import LogoWhite from '../Images/Main/logo_white.png';
import { devicesSmall } from "../devices";
import { Link } from "react-scroll";


export default function Footer() {
    let location = useLocation();
    const history = useHistory();
    const handleScroll = () => {
        if( location.pathname === '/' ) return;
        else return history.push('/');
    }
    return (
        <div id = 'contact' >
            <Container>
                <UpperContainer>
                    <LogoContainer>
                        <img src = {LogoWhite} alt = 'err' layout = 'fill' />
                    </LogoContainer>
                    <DemoContainer>
                        <p>Ready to get started ?</p>
                        <DemoButton onClick = {() => history.push('/demo')} >Request Demo</DemoButton>
                    </DemoContainer>
                </UpperContainer>
                <HrizontalLine />
                <LowerContainer>
                    <LinksContainer>
                        <Link to='courses' spy={true} smooth={true} ><p onClick = {handleScroll} >Courses</p></Link>
                        <Link to='reviews' spy={true} smooth={true} ><p onClick = {handleScroll} >Reviews</p></Link>
                        <p onClick = {() => history.push('/demo')} >Demo</p>
                        <p onClick = {() => history.push('/about')} >About</p>
                    </LinksContainer>
                    <SocialContainer>
                        <a target = '_blank' rel="noreferrer" href = 'https://www.linkedin.com/in/start-from-scratch' ><FacebookIcon /></a>
                        <a target = '_blank' rel="noreferrer" href = 'https://www.linkedin.com/in/start-from-scratch' ><LinkedInIcon /></a>
                        <a target = '_blank' rel="noreferrer" href = 'https://www.instagram.com/sfscratchlive/?utm_medium=copy_link' ><InstagramIcon /></a>
                    </SocialContainer>
                </LowerContainer>
                <Wrapper>
                    <Email>
                        <EmailIcon color = 'white' />
                        <p>admin@sfslive.com</p>
                    </Email>
                    <Phone>
                        <LocalPhoneIcon />
                        <p>+91 - 6376143382</p>
                    </Phone>
                </Wrapper>
                <BottomContainer>
                    <CopyRight>Copyright © 2021. sfs. All rights reserved.</CopyRight>
                </BottomContainer>
            </Container>
        </div>
    )
}

const Container = styled.div`
    background: rgba(25, 25, 25, 0.95);
    backdrop-filter: blur(8px);
    padding: 20px 10px;
    @media ${devicesSmall.tablet}
    {
        padding: 20px 10%; 
    }
`;
const UpperContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    @media ${devicesSmall.tablet}
    {
        flex-direction: row;
        justify-content: space-between;
    }
`;
const LogoContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100px;
    height: 100px;
    display: none;
    >img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    @media ${devicesSmall.tablet}
    {
        display: inline-block;
    }
`;

const DemoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    >p{
        font-weight: 300;
        font-size: 28px;
        line-height: 30px;
        letter-spacing: -0.3px;
        color: #FBFBFC;
    }
    @media ${devicesSmall.tablet}
    {
        >p{
            font-size:20px;
            margin-right: 20px;
        }
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
`;
const DemoButton = styled.button`
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    letter-spacing: -0.231818px;
    color: #FBFBFC;
    background-color:#9E23FF;
    padding: 12px 15px;
    outline: none;
    border: none;
    margin-top: 20px;
    cursor: pointer;
    @media ${devicesSmall.tablet}
    {
        font-size: 15px;  
        padding: 10px 12px;
        flex-direction: row;
        align-items: center;
        transform: translateY(-20%);
    }
`;

const HrizontalLine = styled.hr`
    background: #FBFBFC;
    mix-blend-mode: normal;
    opacity: 0.4;
    margin-top: 20px;
`;

const LowerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media ${devicesSmall.tablet}
    {
        margin-top: 50px;
        flex-direction: row;
        justify-content: space-between;
    }
`;
const LinksContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 15px;
    transform: translateY(-30%);
    cursor: pointer;
    width: 430px;
    >p{
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        letter-spacing: -0.204545px;
        color: #FBFBFC;
    }
    >a{
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        letter-spacing: -0.204545px;
        color: #FBFBFC;
    }
`;
const SocialContainer = styled.div`
    display: flex;
    justify-content: space-between;
    color: white;
    >a{
        padding: 10px 20px;
        cursor: pointer;
    }
    @media ${devicesSmall.tablet}
    {
        >div{
            padding: 0px 20px;
        }
    }
`;
const BottomContainer = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    color: white;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.204545px;
    >p{
        margin-top: 20px;
    }
    @media ${devicesSmall.tablet}
    {
        flex-direction: row;
        justify-content: space-around;
        margin-top: 80px;
        margin-bottom: 20px;
    }
`;
const CopyRight = styled.p`
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.204545px;
    color: #FBFBFC;
    mix-blend-mode: normal;
    opacity: 0.5;
`;

const Wrapper = styled.div`
    color: white;
    margin-top: 20px;
`;
const Email = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    >p{
        margin-left: 10px;
    }
`;
const Phone = styled.div`
    display: flex;
    align-items: center;
    >p{
        margin-left: 10px;
    }
`;


