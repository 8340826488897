import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API}`,
    authDomain: "sfslive-ce43a.firebaseapp.com",
    projectId: "sfslive-ce43a",
    storageBucket: "sfslive-ce43a.appspot.com",
    messagingSenderId: "670762471354",
    appId: "1:670762471354:web:1f032c944acbbe3b545783",
    measurementId: "G-NHJ21C27FR"
  };

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export default firebase;
