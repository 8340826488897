import styled from "styled-components";
import { CourseData } from "../../../Data/CourseData";
import CourseCard from "../../CourseCard/CourseCard";
import SectionComponent from "../../SectionComponent/SectionComponent";

export default function Courses() {
    console.log(CourseData);
    return (
        <div id = 'courses' >
            <SectionComponent
                title='Courses'
                desc='Our Online Classroom Courses'
                para='Interactive learning experience for aspirants'
                id='courses'>
                <Container>
                    {CourseData?.map( item => <CourseCard key = {item?.id} {...item} /> )}
                </Container>
            </SectionComponent>
        </div>
    );
}

const Container = styled.div`
    padding: 5px 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

