import styled from "styled-components";
import SectionComponent from "../SectionComponent/SectionComponent";
// import ArrowLeft from '../../Images/Reviews/ArrowLeft.svg';
// import ArrowRight from '../../Images/Reviews/ArrowRight.svg';
import Comma from '../../Images/Reviews/Comma.svg';
import { useState } from "react";
import OneImg from '../../Images/Reviews/one.jpeg';
import TwoImg from '../../Images/Reviews/two.jpeg';
import ThreeImg from '../../Images/Reviews/three.jpeg';
import { device } from "../../devices";


export default function Testimonials() {
    const [index] = useState(0);
    let TestimonialImageData = [
        {
            id : 1,
            title : 'We have been connected with SFS since the beginning and the kind of personal attention and quality learning expirence they provide is commendable',
            imgUrl : OneImg,
            name : 'Sabina Mustaq'
        },
        {
            id : 2,
            title : 'Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Massa aliquam tristiqu',
            imgUrl : TwoImg,
        },
        {
            id : 3,
            title : 'Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Massa aliquam tristique facilisi nibh tristique justo leo',
            imgUrl : ThreeImg
        }
    ]
    // const nextIndex = () => {
    //     setIndex( (index + 1) % TestimonialImageData.length );
    // }
    // const prevIndex = () => {
    //     setIndex( index === 0 ? TestimonialImageData.length - 1 : index - 1 );
    // }
    return (
        <div id = 'reviews'>
            <Wrapper>
                <SectionComponent title = 'Reviews' desc = 'WHAT THE PARENTS SAY' >
                    
                    <TestimonialData>
                        <Container>
                            <ImageContainer>
                                <ImageValue src = {Comma} alt = 'comma' />
                            </ImageContainer>
                            <h1>{TestimonialImageData[index].title}<br/><span>-- {TestimonialImageData[index].name}</span></h1>
                        </Container>
                        <TestimonialContainer>
                            <TestimonialImage src = {TestimonialImageData[index].imgUrl} alt = 'image' />
                        </TestimonialContainer>
                    </TestimonialData>
                    
                    {/* <ArrowContainer>
                        <img onClick = {() => nextIndex()} src = {ArrowLeft} alt = 'left' />
                        <img onClick = {() => prevIndex()} src = {ArrowRight} alt = 'left' />
                    </ArrowContainer> */}
                </SectionComponent>
            </Wrapper>
        </div>
    )
};

const Wrapper = styled.div`
    position: relative;
`;

const TestimonialData = styled.div`
    align-items: center;
    display: flex;
    padding-bottom: 50px;
    flex-wrap: wrap;
    justify-content: center;
`;

const Container = styled.div`
    display: flex;
    margin-bottom: 20px;
    >h1{
        margin-left: 20px;
        font-weight: 300;
        font-size: 32px;
        margin-top: 30px;
        width: 100%;
        max-width: 750px;
        >span{
            float: right;
            font-size: 28px;
            margin-right: 40px;
        }
    }
    @media screen and (max-width : 425px){
            >h1{
                font-size: 24px;
                
                >span{
                font-size: 20px ;
                }
            }
        }
`;

const ImageContainer = styled.div`
    width: 80px;
    height: 80px;
    @media ${device.mobileL}{
        width: 30px;
    }
`;

const ImageValue = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;


const TestimonialContainer = styled.div`
    width: 100%;
    max-width: 380px;
    height: 100%;
    max-height: 290px;
    @media ${device.laptop}{
        margin-top: 20px;
    }
`;

const TestimonialImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
`;




