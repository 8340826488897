import styled from "styled-components";

export default function PricingCard({name, session, desc, demo, price}) {
    console.log(demo, price);
    return (
        <Container>
            <Plan> {name} </Plan>
            <ValuesConatainer>
                <Values style={{ fontWeight: "400" }} seco>
                    {session} Session
                </Values>
                {desc?.map( ( item, index ) => <Values key = {index} seco = { index % 2 === 0 } >{item}</Values> )}
            </ValuesConatainer>
            <Price> <span>{demo}</span>  {price?.toLocaleString()}</Price>
        </Container>
    );
}

const Container = styled.div`
    width: 100vw;
    height: 100%;
    margin-top: 30px;
    text-align: center;
    background: rgba(251, 251, 252, 0.25);
    box-shadow: 4px 4px 28px rgba(25, 25, 25, 0.14);
    backdrop-filter: blur(8px);
    border-radius: 12px;
    padding: 20px 0px 0px 0px;
    @media screen and (min-width : 800px){
        max-width: 366px;
    }
`;

const Plan = styled.div`
    text-transform: uppercase;
    font-size: 25px;
    line-height: 25px;
    color: #191919;
    opacity: 0.7;
    font-weight: bold;
`;
const ValuesConatainer = styled.div`
    margin-top: 25px;
`;
const Values = styled.div`
    padding: 20px 20px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    background-color: ${(props) =>
        props.seco ? "rgba(0, 255, 255, 0.15)" : "white"};
`;
const Price = styled.div`
    background: #9e23ff;
    border-radius: 0px 0px 12px 12px;
    color: white;
    font-size: 34px;
    line-height: 48px;
    padding: 15px 0px;
    > span {
        font-size: 28px;
        font-weight: bold;
    }
`;
