import styled from "styled-components";
import IITD from "../../../Images/colleges/iitd.png";
import Bits from "../../../Images/colleges/bits.png";
import IIITH from "../../../Images/colleges/iiith.png";
import LNM from "../../../Images/colleges/lnm.png";
import Georgia from "../../../Images/colleges/georgia.png";
import Tooltip from '@mui/material/Tooltip';

export default function Team() {
    return (
        <Container>
            <Title>Brought to you by a team from</Title>
            <ImageWrapper>
                <Tooltip title = 'IIT Delhi' arrow>
                    <Img1
                        src={IITD}
                        alt='err'
                    />
                </Tooltip>

                <Tooltip title = 'Gerogia Institute of Technology' arrow>
                    <Img2
                        src={Georgia}
                        alt='err'
                    />
                </Tooltip>

                <Tooltip title = 'BITS Pilani' arrow>
                    <Img1
                        src={Bits}
                        alt='err'
                    />
                </Tooltip>

                <Tooltip title = 'IIIT Hyderabad' arrow>
                    <Img2
                        src={IIITH}
                        alt='err'
                    />
                </Tooltip>

                <Tooltip title = 'LNMIIT Jaipur' arrow>
                    <Img2
                        src={LNM}
                        alt='err'
                    />
                </Tooltip>
            </ImageWrapper>
        </Container>
    );
}

const Container = styled.div`
    background: rgba(251, 251, 252, 0.25);
    box-shadow: 4px 4px 24px rgba(25, 25, 25, 0.12);
    backdrop-filter: blur(8px);
    border-radius: 4px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 40px;
    margin-bottom: 40px;
    @media screen and (min-width : 768px){
        margin: 85px 45px 45px 40px;
    }
`;
const Title = styled.div`
    width: 100%;
    text-align: center;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #191919;
    opacity: 0.4;
    margin-bottom: 10px;
    @media screen and (min-width : 768px){
        margin-bottom: 30px;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.05em;
    }
`;
const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
    >img{
        margin: 0px 10px;
        margin-bottom: 10px;
    }
`;

const Img1 = styled.img`
    width: 64px;
    height: 68px;
    @media screen and (min-width : 768px ){
        width: 100px;
        height: 102px;
    }
`;

const Img2 = styled.img`
    width: 140px;
    height: 68px;
    @media screen and (min-width : 768px ){
        width: 211px;
        height: 100px;
    }
`;

