import { useState } from "react";
import styled from "styled-components";
import ExpandIcon from "../../Images/Courses/expand.svg";
import LessonIcon from "../../Images/Courses/lessonIcon.png";

export default function Accordian({title, values}) {
    const [show, setShow] = useState(false);
    return (
        <Container>
            <AccordianTitle onClick = {() => setShow(!show)} >
                {title}
                <StyledArrow src={ExpandIcon} alt='err' show = {show} />
            </AccordianTitle>
            <AccordianData show={show}>
                {values?.map( item => (
                    <Value key = {item?.id}>
                        <img src={LessonIcon} alt='err' />
                        <p>{item?.value}</p>
                    </Value>
                ) )}
            </AccordianData>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    max-width: 768px;
    margin-top: 20px;
`;

const AccordianTitle = styled.div`
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    background: rgba(251, 251, 252, 0.1);
    box-shadow: 4px 4px 24px rgba(25, 25, 25, 0.1);
    border-radius: 12px;
    padding: 12px 10px;
    font-size: 18px;
    letter-spacing: 1.2px;
    margin-bottom: 10px;
    cursor: pointer;
`;
const StyledArrow = styled.img`
    transform: ${props => props.show && 'rotate(180deg)'}
`;
const AccordianData = styled.div`
    display: ${(props) => (props.show ? "inline-block" : "none")};
    background: #fbfbfc;
    box-shadow: 4px 4px 24px rgba(25, 25, 25, 0.1);
    backdrop-filter: blur(4px);
    border-radius: 8px;
    margin-bottom: 10px;
    width: 100%;
    transition: all 0.2s ease-in;
`;
const Value = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 1.3px;
    > p {
        margin-left: 10px;
    }
`;
