import styled from "styled-components";

import "./SectionComponent.css";
export default function SectionComponent({ children, title, desc, para }) {
    return (
        <div className='section'>
            <Wrapper>
                <ContainerTitle>
                    <span>{title}</span>
                    <h1>{desc}</h1>
                    <p>{para}</p>
                </ContainerTitle>
                <ChildrenComponent>{children}</ChildrenComponent>
            </Wrapper>
        </div>
    );
}

const Wrapper = styled.div`
    padding: 15px;
    margin: 100px 40px 40px 30px;
    border-width: 10px;
    box-shadow: 4px 4px 24px rgba(25, 25, 25, 0.1);
    backdrop-filter: blur(4px);
    background: rgba(251, 251, 252, 0.15);
    border-radius: 8px;
    height: auto;
    transition: all 0.4s ease-in-out;
    
    @media screen and (max-width: 768px) {
        margin: 64px 0px;
        border-radius: 0px;
        border-style: solid;
        border-width: 2px 0px 0px 0px;
        border-image: linear-gradient(90deg, #9e23ff, #00ffff) 1;
    }
`;

const ContainerTitle = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    > span {
        font-family: var(--font-main);
        font-style: normal;
        font-weight: normal;
        font-size: 21px;
        line-height: 31px;
        letter-spacing: -0.002em;
        color: #9e23ff;
        margin-bottom: 10px;
    }
    > h1 {
        width: 100%;
        max-width: 428px;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 42px;
    }
    > p {
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        margin-top: 20px;
    }
    @media screen and (max-width: 768px) {
        > span {
            font-size: 16px;
            line-height: 24px;
        }
        > p {
            font-weight: 300;
            font-size: 18px;
            line-height: 18px;
        }
        > h1 {
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
        }
    }
`;

const ChildrenComponent = styled.div`
    margin-top: 50px;
    position: relative;
`;
