import styled from "styled-components";

export default function HeroDivider() {
    return (
        <Container>
            <Wrapper>
                <p>Discover the joy of online learning</p>
                <BgOne right = '-40px' top = '-20' color = '#FFE500' />
            </Wrapper>

            <Wrapper>
                <p>Courses to fit in all your child’s needs</p>
                <BgTwo color = '#9E23FF' />
            </Wrapper>

            <Wrapper>
                <p>A new era of personalised learning</p>
                <BgThree color = '#00FFFF' />
            </Wrapper>
        </Container>
    );
}

const Container = styled.div`
    width: 100vw;
    display: grid;
    align-items: center;
    justify-items: center;
    background: rgba(251, 251, 252, 0.05);
    box-shadow: 4px 4px 24px rgba(25, 25, 25, 0.2);
    backdrop-filter: blur(4px);
    margin-top: 20px;
    margin-bottom: 20px;
`;
const Wrapper = styled.div`
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    width: 50%;
    margin: 20px 0px;
    position: relative;
    margin-bottom: 40px;
`;

const BgColor = styled.div`
    width: 140px;
    height: 100px;
    border: 4px solid;
    border-color: ${props => props.color};
    z-index : -2;
`;

const BgOne = styled(BgColor)`
    position: absolute;
    left: -40px;
    top: -10px;
`;

const BgTwo = styled(BgColor)`
    position: absolute;
    top: 0px;
    right: -40px;
`;

const BgThree = styled(BgColor)`
    position: absolute;
    top : 0px;
    left : -40px
`;

