import { v4 as uuidv4 } from "uuid";

export const PrincingData = [
    {
        id : uuidv4(),
        name : 'Plan Type 1',
        session : 24,
        desc : ['Course Material', 'Regular Graded Assessments', 'Live Support', 'Doubt Clearing Classes', 'Personalised Progress Report'],
        cost : [ 12000, 600, 155]
    },
    {
        id : uuidv4(),
        name : 'Plan Type 2',
        session : 48,
        desc : ['Course Material', 'Regular Graded Assessments', 'Live Support', 'Doubt Clearing Classes', 'Personalised Progress Report'],
        cost : [ 20000, 1000, 270]
    },
    {
        id : uuidv4(),
        name : 'Plan Type 3',
        session : 72,
        desc : ['Course Material', 'Regular Graded Assessments', 'Live Support', 'Doubt Clearing Classes', 'Personalised Progress Report'],
        cost : [ 24000, 1200, 325]
    }
];