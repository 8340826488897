import { useHistory } from "react-router";
import styled from "styled-components";
import convertToSlug from "../../Utils/converToSlug";
export default function CourseCard({department, name, imgUrl}) {
    const history = useHistory();
    let slug = convertToSlug(name);
    return (
        <Container onClick = {() => history.push(`/course/${slug}`)} >
            <ImageContainer>
                <img src={imgUrl} alt='err' />
            </ImageContainer>
            <DataContainer>
                <Deparment>{department}</Deparment>
                <Title>
                    {name}
                </Title>
                <Sessions>24 - 72 Sessions</Sessions>
            </DataContainer>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    max-width: 320px;
    height: 100%;
    max-height: 412px;
    background: rgba(251, 251, 252, 0.4);
    box-shadow: 0px 8px 36px 2px rgba(25, 25, 25, 0.1);
    backdrop-filter: blur(12px);
    border-radius: 24px;
    margin-top: 10px;
    margin-bottom: 32px;
    transition: 0.3s all ease-in-out;
    @media screen and (min-width : 768px){
        cursor: pointer;
        :hover{
            margin-top: 0px;
            margin-bottom: 42px;
        }
    }
`;
const ImageContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 24px;
    padding: 6px;
    max-height: 240px;
    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 24px;
    }
`;
const DataContainer = styled.div`
    padding: 5px 20px 15px 20px;
    @media screen and (min-width : 768px){
        margin-top: 10px;
    }
`;
const Deparment = styled.div`
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    background: #ffe500;
    opacity: 0.9;
    border-radius: 8px;
    text-align: center;
    padding: 2px 16px;
    font-weight: bold;
    margin-bottom: 10px;
`;
const Title = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
`;
const Sessions = styled.div`
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: blueviolet;
    margin-top: 12px;
    margin-bottom: 5px;
`;
