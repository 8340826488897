import styled from "styled-components";

export default function AboutCards({
    title,
    seco = false,
    secoTitle = "about",
}) {
    return (
        <Container seco={seco}>
            <TitleContainer>
                <Title seco={seco}>{title}</Title>
                <BorderMargin seco={seco} />
            </TitleContainer>
            <CardContainer>
                <p>
                    Start from scratch is an initiative designed after
                    recognizing the potholes in our traditional educational
                    system to offer a more holistic approach to learning that
                    develops students to be more critical, confident, and
                    independent. We provide personalized, interactive learning
                    programs for students of classes 1-12. We started with a
                    handful of students and mere 3 of our earlier founders and
                    are now operating in 6 different countries six months in,
                    with a team of 25+ individuals that works relentlessly to
                    offer exceptional educational opportunities to far and wide
                    communities.
                </p>
                <TitleBottom>
                    <CartTitle seco={seco}>{secoTitle}</CartTitle>
                </TitleBottom>
            </CardContainer>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: ${(props) => props.seco && "row-reverse"};
    margin-right: ${(props) => (props.seco ? "0%" : "10%")};
    margin-left: ${(props) => (props.seco ? "10%" : "0%")};
    margin-bottom: 15%;

    @media screen and (max-width: 1100px) {
        margin-right: ${(props) => (props.seco ? "0%" : "3%")};
        margin-left: ${(props) => (props.seco ? "3%" : "0%")};
    }

    @media screen and (max-width: 940px) {
        display: flex;
        flex-direction: column;
        margin-right: 0px;
        margin-left: 0px;
    }
`;
const TitleContainer = styled.div`
    margin-bottom: 10px;
`;

const Title = styled.p`
    font-weight: bold;
    font-size: 90px;
    line-height: 96px;
    color: #191919;
    text-transform: uppercase;
    opacity: 0.4;
    padding: 5px 50px;
    @media screen and (max-width: 425px) {
        text-align: ${(props) => (props.seco ? "end" : "start")};
        font-size: 48px;
        padding: 5px 20px;
        line-height: 25px;
        margin-bottom: 0px;
    }
`;

const BorderMargin = styled.div`
    width: 120%;
    margin-right: ${(props) => (props.seco ? "0%" : "-20%")};
    margin-left: ${(props) => (props.seco ? "-20%" : "0%")};
    height: 56px;
    background-color: ${(props) => (props.seco ? "#FFE500" : "#00FFFF")};
    margin-top: -40px;
    @media screen and (max-width: 425px) {
        text-align: end;
        display: inline-block;
        width: 50%;
        height: 30px;
        margin-right: 0px;
        margin-left: 0px;
        float: ${(props) => props.seco && "right"};
        margin-top: -20px;
    }
`;

const CardContainer = styled.div`
    background: rgba(251, 251, 252, 0.25);
    box-shadow: 4px 4px 28px rgba(25, 25, 25, 0.14);
    backdrop-filter: blur(0.1px);
    border-radius: 12px;
    padding: 50px 60px 20px 60px;
    > p {
        font-weight: 300;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: 0.04em;
        width: 90%;
    }
    @media screen and (max-width: 940px) {
        padding: 20px 30px 20px 30px;
        text-align: center;
        > p {
            width: 100%;
        }
    }
`;

const TitleBottom = styled.div`
    margin-bottom: -90px;
    margin-left: -30px;
    margin-top: -20px;
    @media screen and (max-width: 940px) {
        display: none;
    }
`;

const CartTitle = styled.p`
    font-style: normal;
    font-weight: bold;
    font-size: 8rem;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    opacity: 0.3;
    color: ${(props) => (props.seco ? "#FFE500" : "#00FFFF")};
`;
